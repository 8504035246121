// Import dependencies
import React from "react"
import { Parallax } from "react-parallax"

// Import components
import Layout from "../components/layouts/layout"
import SEO from "../components/seo/seo"
import Header from "../components/header/header"
import {
  PageContainer,
  Margins,
  Gap150,
  TitleText,
  ParagraphText,
} from "../components/styled/UILibrary"
import TextSection from "../components/general/textSection"
import ContactCallToAction from "../components/general/contactAction"

// Import styles
import "../components/general/outlinebutton.css"
import "./styles/history.css"
import "./styles/about.css"
import "./styles/scrollbar.css"

// Import images
let about1 = require("../assets/images/about-1.jpg")
let about2 = require("../assets/images/about-2.jpg")

export default class AboutPage extends React.Component {
  componentDidMount() {
    // Hello for developers
    console.clear()
    console.log(
      "%cDesigned & developed by Dmitry Poliyivets – https://www.linkedin.com/in/dpoliyivets/",
      "background: #0000FF; color: #ffffff; font-size: 12px; padding: 25px; font-family: 'Montserrat', sans-serif; line-height: 22px"
    )
  }

  render() {
    return (
      <Layout>
        <SEO title="About" keywords={[`gatsby`, `application`, `react`]} />

        <div className="history__container">
          <Header />
          {/* Page contents */}
          <PageContainer>
            <Margins>
              <Gap150 />

              <TextSection
                smallTitle="ABOUT SPIROPLASTIC"
                title="Rooted in tradition, always looking forward"
                paragraph="From our earliest days to today, we have been at the forefront of introducing recycled materials into the production chain."
              />

              <Gap150 name="our-history" />

              <TextSection
                smallTitle="OUR HISTORY"
                title="A family story"
                paragraph="Spiros Moros, our founder’s grandfather, opened his doors for business in a uniquely different era and a vastly different market. We date back to the 1930s when we were known as Lion Glue. In those early days, we made glue by boiling collagen out of animal bones. Factory production really heated up when the British army placed a large order for a glue that was to be mixed with sand and used in tanks."
              />
              <ParagraphText
                minSize={18}
                maxSize={20}
                style={{ marginTop: 40 }}
              >
                One of Spiros Moros' talents was his ability to see how a
                business can progress in positive directions that benefit
                everyone - the company, customers, workers, and the larger
                community. Two decades later the company added plastics to their
                offerings and were among the first anywhere to adopt a recycling
                strategy. We were recycling and manufacturing PVC as early as
                1950.
              </ParagraphText>
              <ParagraphText
                minSize={18}
                maxSize={20}
                style={{ marginTop: 40 }}
              >
                This story would not be complete without the legacy of family.
                Costas Moros, Spiros’ father, who in the spirit of progress was
                experimenting in his own kitchen and accidently came up with the
                recipe for acrylic. He went on to industrialise and standardise
                the recipe for acrylic sheets production.
              </ParagraphText>

              <Gap150 />

              <Parallax
                bgImage={about1}
                bgImageAlt="about-us-worker"
                strength={300}
              >
                <div className="about__parallax-container">
                  <TitleText
                    minSize={22}
                    maxSize={40}
                    white
                    shadow
                    style={{ width: "80%" }}
                  >
                    You contribute to the transformation of waste into a first
                    class product.
                  </TitleText>
                </div>
              </Parallax>

              <Gap150 name="philanthropy" />

              <TextSection
                smallTitle="GIVING BACK"
                title="Philanthropy"
                paragraph="The founder also established himself as a philanthropist. After his own successful fight with cancer, he founded the Spiros Moros Charity Foundation, a Non-Governmental Organization registered at the Ministry of Social Solidarity (Reg. #4819 the year 2000). It aims at achieving a better healthy life for the community by raising awareness about healthy nutrition, breastfeeding, holistic medicine, bio-farming, and cancer through the organization of seminars, workshops, and different media tools. The foundation sponsors applied research in these fields."
              />
              <ParagraphText
                minSize={18}
                maxSize={20}
                style={{ marginTop: 40 }}
              >
                The foundation translates and publishes select international
                books. It also exchanges information and experiences with
                national, regional, and international organizations. The books
                are offered by the foundation office at a reduced price.
              </ParagraphText>
              <div style={{ marginTop: 30, width: 220 }}>
                <a
                  href="http://www.spirofoundation.com/"
                  className="outlinebutton__container"
                  target="_blank"
                >
                  <ParagraphText minSize={14} maxSize={16} link>
                    FOUNDATION WEBSITE
                  </ParagraphText>
                </a>
              </div>

              <Gap150 />

              <Parallax
                bgImage={about2}
                bgImageAlt="about-us-workers"
                strength={300}
              >
                <div className="about__parallax-container">
                  <TitleText
                    minSize={22}
                    maxSize={40}
                    white
                    shadow
                    style={{ width: "80%" }}
                  >
                    You contribute to a better future
                  </TitleText>
                </div>
              </Parallax>

              <Gap150 name="modern-day" />

              <TextSection
                smallTitle="MOVING FORWARD"
                title="Modern day"
                paragraph={
                  "Today, as we approach our first century in business, Spiroplastic has demonstrated an ability to innovate responsible recycling while maintaining a competitive market share. We are one of Egypt's oldest and most successful plastic company. We are a legacy. Let us bring first class product and production methods into your production chain."
                }
              />

              <Gap150 />

              <ContactCallToAction />
            </Margins>
          </PageContainer>
        </div>
      </Layout>
    )
  }
}
